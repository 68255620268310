<template>

    <div class="ui segment">
        <div class="ui middle aligned divided list">
            <condition-item>
                <template v-slot:name>Fizetési mód</template>
                <template v-slot:value>
                    <span v-if="contract && contract.payMethod === 'bank'">Átulalással</span>
                    <span v-else-if="contract && contract.payMethod === 'cash'">Készpénzben</span>
                    <span v-else-if="contract">Más módon</span>
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Fizetési határidő, napokban</template>
                <template v-slot:value>{{ contract && contract.prompt }} nap</template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Gyakoriság</template>
                <template v-slot:value>
                    <span v-if="contract && contract.billingPeriod === -1">Utólag</span>
                    <span v-else-if="contract">Előre, {{ contract.billingPeriod }} hónap</span>
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Számlára kerülő megjegyzés</template>
                <template v-slot:value>
                    <template v-if="contract.billingNote">
                        {{ contract.billingNote }}
                    </template>
                    <template v-else>
                        Nem kerül a számlára megjegyzés
                    </template>
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Hány darab számlát fog kapni?</template>
                <template v-slot:value>
                    {{ contract.invoiceGroupNr }} db számlát
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Számla kiállítás napja</template>
                <template v-slot:value>
                    {{ contract.billingDayOfMonth }}-én (-án)
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Számlafogadó e-mail címek</template>
                <template v-slot:value>
                    <div class="ui divided list">
                        <div v-for="recipientEmail in contract.billRecipientEmails"
                             class="item" :key="recipientEmail.id">
                            {{ recipientEmail.email }}
                        </div>
                    </div>
                </template>
            </condition-item>
        </div>
    </div>



</template>



<script setup>

import ConditionItem from "@/components/contract/create/checkOneCondition.vue";
import {useStore as useVuexStore} from "vuex";
import {computed} from "vue";

const store_vuex = useVuexStore();
const contract = computed(() => store_vuex.state.updateContract.contract);

</script>


<style scoped>

</style>