import axios from "axios";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import {useReContractSiteStore} from "@/store-pinia/reContractSite";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {useContractStore} from "@/store-pinia/api/contract";
import {useCalculationStore} from "@/store-pinia/calculation";
import {useChkUserStore} from "@/store-pinia/api/chkUser";
import {useOroStore} from "@/store-pinia/oro";
import {notifyAdmin3} from "@/store-pinia/api/notifyAdmin3";

const state = () => ({

    contract: null,
    activeAccordionIndex: -1,
    contractIsReady: false,

    editedSite: null,
    settingSite: null,
    editedCost: null,
    editedPersonRole: null,
    calculationResult: [],
    priceList: [],
    sending: false,


    showNewSiteModal: false,
    showEditSiteModal: false,
    showSettingsSiteModal: false,
    showAddCostModal: false,
    showAddCostModalFromPriceList: false,
    showPriceListModal: false,
    showCostsDetailingModal: false,


    showEditConditionsAModal: false,
    showEditConditionsBModal: false,

    showSiteTerminateRequestModal: false,
    showCostTerminateRequestModal: false,

    showSiteCostsInPeriodModal: false,
    showSitesInPeriodModal: false,

    showEditCustomerModal: false,
    showEditPersonModal: false,

    showCostDeepTestingModal: false,

    showSiteSettingCommissionsModal: false,
    showSiteSettingDiscountsModal: false,
    showSiteSettingDateOfLastIssuedInvoiceModal: false,


    acceptTerminateDays: 14,

    transitionSites: [],

    test: 'TESZT ELEK'

})

const getters = {}

const mutations = {
    setContract(state, contract) {
        const pinia_priceListStore = useContractPriceListStore();
        const pinia_contractStore = useContractStore();

        state.contract = contract
        pinia_priceListStore.setContract(contract)
        pinia_contractStore.setContract(contract)
    },
    setActiveAccordionIndex(state, active_index) {
        state.activeAccordionIndex = active_index
    },
    setContractIsReady(state, is_ready) {
        state.contractIsReady = is_ready
    },
    setEditedSite(state, site) {
        state.editedSite = site
    },
    setSettingSite(state, site) {
        state.settingSite = site
    },
    setEditedCost(state, cost) {
        state.editedCost = cost
    },

    setEditedPersonRole(state, role) {
        state.editedPersonRole = role
    },

    setCalculationResult(state, calculationResult) {
        state.calculationResult = calculationResult
    },
    setPriceList(state, priceList) {
        state.priceList = priceList
    },
    setSending(state, sending) {
        state.sending = sending
    },
    setShowNewSiteModal(state, showModal) {
        state.showNewSiteModal = showModal
    },
    setShowEditSiteModal(state, showModal) {
        state.showEditSiteModal = showModal
    },
    setShowSettingsSiteModal(state, showModal) {
        state.showSettingsSiteModal = showModal
    },

    setShowEditConditionsModal(state, showModal) {
        state.showEditConditionsModal = showModal
    },

    setShowEditConditionsAModal(state, showModal) {
        state.showEditConditionsAModal = showModal
    },

    setShowEditConditionsBModal(state, showModal) {
        state.showEditConditionsBModal = showModal
    },


    setShowSiteTerminateRequestModal(state, showModal) {
        state.showSiteTerminateRequestModal = showModal
    },

    setShowSiteCostsInPeriodModal(state, showModal) {
        state.showSiteCostsInPeriodModal = showModal
    },
    setShowSitesInPeriodModal(state, showModal) {
        state.showSitesInPeriodModal = showModal
    },

    setShowCostTerminateRequestModal(state, showModal) {
        state.showCostTerminateRequestModal = showModal
    },
    setShowAddCostModal(state, showModal) {
        state.showAddCostModal = showModal
    },
    setShowAddCostFromPriceListModal(state, showModal) {
        state.showAddCostModalFromPriceList = showModal
    },
    setShowPriceListModal(state, showModal) {
        state.showPriceListModal = showModal
    },
    setShowCostsDetailingModal(state, showModal) {
        state.showCostsDetailingModal = showModal
    },

    setShowEditCustomerModal(state, showModal) {
        state.showEditCustomerModal = showModal
    },
    setShowEditPersonModal(state, showModal) {
        state.showEditPersonModal = showModal
    },

    setShowCostDeepTestingModal(state, showModal) {
        state.showCostDeepTestingModal = showModal
    },

    setShowSiteSettingCommissionsModal(state, showModal) {
        state.showSiteSettingCommissionsModal = showModal
    },
    setShowSiteSettingDiscountsModal(state, showModal) {
        state.showSiteSettingDiscountsModal = showModal
    },
    setShowSiteSettingDateOfLastIssuedInvoiceModal(state, showModal) {
        state.showSiteSettingDateOfLastIssuedInvoiceModal = showModal
    },

    setTransitionSites(state, transitions_sites) {
        state.transitionSites = transitions_sites
    },
    addToTransitionSites(state, transition_site_id) {
        state.transitionSites.push(transition_site_id)
    },
    removeFromTransitionSites(state, transition_site_id) {
        state.transitionSites = state.transitionSites.filter(el => el !== transition_site_id);
    },

    toggleHideTerminatedSites(state) {
        state.contract.hideTerminatedSites = !state.contract.hideTerminatedSites
    },

    setTestElek(state, test_msg) {
        state.test = test_msg
    },

}

const actions = {


    async fetchContract({rootState, commit}, contract_id) {
        try {
            const storeReContract = useReContractSiteStore();
            const storeCalculation = useCalculationStore();
            const storeChkUserStore = useChkUserStore();
            const storeOro = useOroStore();

            commit("setContractIsReady", false);

            const axiosConfig = setupAuthHeaderFor('wsse');
            const urlContract = `${rootState.app.hostServer}/api/contract-info/${contract_id}`;

            const response = await axios.get(urlContract, axiosConfig);
            commit('setContract', response.data);
            storeReContract.setNewSiteContract(response.data);
            storeCalculation.setPriceList(response.data.priceList);


            const axiosConfigJWT = setupAuthHeaderFor('jwt');
            const q = {
                'exists[oroUser]': true,
                'order[fullname]': 'asc'
            }
            const queryString = new URLSearchParams(q).toString();
            const url_chk_users = `${storeOro.admin3.host}/api/users?${queryString}`;
            const responseChkUser = await axios.get(url_chk_users, axiosConfigJWT);

            storeChkUserStore.setUsers(responseChkUser.data);

        } catch (error) {
            console.error("Hiba történt a szerződés lekérdezése közben:", error);
        } finally {
            commit("setContractIsReady", true);
        }
    },


    async deleteContractItem({rootState, dispatch}, item) {
        try {
            const axiosConfig = setupAuthHeaderFor('wsse');
            const urlContractItem = `${rootState.app.hostServer}/api/contractitems/${item.id}`;

            await axios.delete(urlContractItem, axiosConfig);
            await dispatch('fetchContract', item.contract);
        } catch (error) {
            console.error("Hiba történt a szerződési elem törlése közben:", error);
        }
    },


    async fetchProductType({rootState, commit}, product_type_id) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_productType = rootState.app.hostServer + '/api/adminproducttypeentities/' + product_type_id;
        await axios.get(url_productType, axiosConfig)
            .then(resp => {
                commit('setProductTypeInfo', resp.data)
            })
    },

    setProductTypeId({commit, dispatch}, product_type_id) {
        commit('setProductTypeId', product_type_id)
        dispatch('fetchProductType', product_type_id)
    },

    async fetchPriceList({rootState, commit, state}, loyalty) {

        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_price_list = rootState.app.hostServer + '/api/price-list'

        const priceList = {
            entity: "contract",
            entityId: state.contract.id,
            loyalty: loyalty
        }

        const send_data = {
            priceList: priceList
        }

        await axios.post(url_price_list, send_data, axiosConfig)
            .then(resp => {
                commit('setPriceList', resp.data)
            });
    },

    async saveNewContractItems({state, dispatch, commit}) {
        try {
            commit('setSending', true);

            for (let item of state.newItems) {
                await dispatch('createContractItem', {contract: state.contract.id, ...item});
            }

            commit('saveNewItems', []);
            await dispatch('fetchContract', state.contract.id);
        } catch (error) {
            console.error("Hiba történt a contract elemek mentése közben:", error);
        } finally {
            commit('setSending', false);
        }
    },


    async createSite({rootState, state, dispatch, commit}, data) {
        try {
            commit('setSending', true);

            const axiosConfig = setupAuthHeaderFor('wsse');
            const urlItem = `${rootState.app.hostServer}/api/contractitems`;

            const addressData = {
                postalCode: 0,
                city: data.city,
                street: data.street,
                country: 'HU'
            };

            const createdAddress = await dispatch('createAddress', addressData);

            const sendingSiteData = {
                contract: state.contract.id,
                nameAtContract: data.name,
                endOfLoyalty: null,
                address: createdAddress.id
            };

            const response = await axios.post(urlItem, sendingSiteData, axiosConfig);
            const createdSite = response.data;

            for (let costData of state.calculationResult.costs) {
                await dispatch('createItemCost', {item: createdSite.id, ...costData});
            }

            await dispatch('fetchContract', state.contract.id);
        } catch (error) {
            console.error("Hiba történt az oldal létrehozása közben:", error);
        } finally {
            commit('setSending', false);
        }
    },


    async createItemCost({rootState}, data) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_cost = rootState.app.hostServer + '/api/itemcosts'
        const send_data = {
            item: data.item,
            name: data.name,
            unitPriceMonthly: data.priceMonthly,
            unitPriceOneTime: data.priceOneTime,
            costType: data.costType
        }

        console.log('item_costs_send_data', send_data)

        return await axios.post(url_cost, send_data, axiosConfig)
            .then((resp) => {
                return resp.data
            })
    },

    async createAddress({rootState}, data) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_address = rootState.app.hostServer + '/api/addresses'
        return await axios.post(url_address, data, axiosConfig)
            .then((resp) => {
                return resp.data
            })
    },


    async deleteCost({rootState, dispatch, state}, cost_id) {
        try {
            const axiosConfig = setupAuthHeaderFor('wsse');
            const urlItemCost = `${rootState.app.hostServer}/api/itemcosts/${cost_id}`;

            await axios.delete(urlItemCost, axiosConfig);

            await dispatch("fetchContract", state.contract.id);
        } catch (error) {
            // Hiba kezelése, ha szükséges.
            console.error("Hiba történt a költség törlése közben:", error);
        }
    },


    async updateItem({rootState, dispatch, state}, site) {
        const {app} = rootState;
        const axiosConfig = setupAuthHeaderFor('wsse');

        const urlItem = `${app.hostServer}/api/contractitems/${site.id}`;

        try {
            await axios.patch(urlItem, {nameAtContract: site.nameAtContract}, axiosConfig);

            const address = site.address;
            await dispatch('updateAddress', address);
            await dispatch("fetchContract", state.contract.id);
        } catch (error) {
            console.error("Hiba történt az elem frissítése közben:", error);
        }
    },


    async deleteSite({rootState, commit, dispatch, state}, site) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        commit('setSending', true);
        const url_site = rootState.app.hostServer + '/api/contractitems/' + site.id
        console.log('url_site: ', url_site);

        await axios.delete(url_site, axiosConfig)
            .then(() => {
                dispatch('fetchContract', state.contract.id)
            });

        await commit('setSending', false);
    },

    async updateAddress({rootState}, address) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_address = rootState.app.hostServer + '/api/addresses/' + address.id

        const send_address_data = {...address}
        delete send_address_data.id
        return await axios.patch(url_address, send_address_data, axiosConfig)
            .then((response) => {
                return response.data
            })
    },


    async updateContract({rootState, dispatch}, contract) {

        const payload = {...contract};
        delete payload.id;

        const axiosConfig = setupAuthHeaderFor('wsse');
        const contractUrl = rootState.app.hostServer + '/api/simple-contract-update/' + contract.id

        await notifyAdmin3('contract', contract.id)
        await axios.post(contractUrl, payload, axiosConfig);

        dispatch("fetchContract", contract.id);
    },


    async terminateRequest({rootState, dispatch, commit}, data) {

        let url_terminate_request = '';

        if (data.scope === 'site') {
            url_terminate_request = rootState.app.serviceAdminServer + '/terminate-request-site/' + data.site.id;
        }

        if (data.scope === 'cost') {
            url_terminate_request = rootState.app.serviceAdminServer + '/terminate-request-cost/' + data.cost.id;
        }


        const contract_id = data.contract_id

        const axiosConfig = setupAuthHeaderFor('wsse');

        await axios.post(url_terminate_request, data, axiosConfig)
            .then(() => {
                commit('setShowSiteTerminateRequestModal', false)
                commit('setShowCostTerminateRequestModal', false)
            })
            .then(() => {
                dispatch('fetchContract', contract_id)
            })
    },


    async costDeepTest({rootState, dispatch, commit}, data) {

        const url_costDeepTest = `${rootState.app.serviceAdminServer}/deep-testing/cost-dates/${data.cost_id}`;
        const {contract_id} = data;

        const axiosConfig = setupAuthHeaderFor('jwt');

        try {
            await axios.post(url_costDeepTest, data, axiosConfig);
            await dispatch('fetchContract', contract_id);
        } catch (error) {
            console.error("Hiba történt a költség dátumainak (tesztelés miatti) módosítása során:", error);
        } finally {
            commit('setShowCostDeepTestingModal', false);
        }
    },


    async addCost({dispatch, commit, state}) {
        try {
            const newCosts = state.calculationResult.costs;
            const siteId = state.editedSite.id;

            for (let costData of newCosts) {
                await dispatch('createItemCost', {item: siteId, ...costData});
            }

            commit('setShowAddCostModal', false);
            commit('setEditedSite', null);
            await dispatch('fetchContract', state.contract.id);
        } catch (error) {
            console.error("Hiba történt a költség hozzáadása közben:", error);
        }
    },


    toServiceEdit({rootState}, service_id) {
        window.location.href = `${rootState.app.serviceAdminServer}/service/setting/${service_id}`
    },


    async makeNewContractType({rootState, commit, dispatch, state}) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_reContract = `${rootState.app.hostServer}/api/transfer-to-preferred-contract`

        await axios.post(url_reContract, state.transitionSites, axiosConfig)
            .then((response) => {
                commit('setTransitionSites', [])
                return response.data
            })
            .then((data) => {
                dispatch('fetchContract', data.new_contract_id)
            })
            .catch((err) => {
                console.log('Hiba az átszerződés létrehozásánál', err)
            })
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
