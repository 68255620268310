import axios from 'axios';
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import {useOroStore} from "@/store-pinia/oro";

export async function notifyAdmin3(className, entityId) {

    const store_oro = useOroStore();

    const axiosConfig = setupAuthHeaderFor('jwt');
    const url = `${store_oro.admin3.host}/oro-notify/${className}/${entityId}`;
    return await axios.get(url, axiosConfig);

}